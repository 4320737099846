import React, { useState } from "react";
import LessonLayout from "~/layouts/lesson";
import TeacherViewWrapper from "~/layouts/teacher-view-wrapper";
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import { StudentCard, TeacherCard } from "~/components/content-card";
import OrderedList from "~/components/List/Ordered";

const Page = () => {
  const [studentFullScreen, setStudentFullScreen] = useState<boolean>(false);
  const [teacherFullScreen, setTeacherFullScreen] = useState<boolean>(false);

  return (
      <LessonLayout
        current_step={10}
        lesson={"Lesson 1"}
        color={"ms"}
        total_count={10}
        module_title="Separating the Good from the Bad"
      >
        <ContentWrapper>
          <H2 underline>Step 10</H2>

          <TeacherViewWrapper>
            <StudentCard
              fullScreen={studentFullScreen}
              hidden={teacherFullScreen}
              highSchool={false}
              setFullScreen={setStudentFullScreen}
            >
              <p>Your teacher will wrap up this lesson with a class discussion. Take a few minutes to think about how you would answer questions that will be part of that discussion. Share ideas with your teammates.</p>

              <OrderedList>
                <li>Suppose you have found information from 2 different sources. One is a website and one is a magazine. Would your rubric be helpful for thinking about the quality of information in both sources? What criteria on your rubric would apply to both sources? Are there criteria that do not apply to both sources?</li>
                <li>At one time, polio was a disease that crippled many people. Suppose you want to find out how Jonas Salk developed a vaccine to protect people from getting polio. Are there any website features that would be either more important or less important when deciding which sites might be better for answering your question? Why? </li>
                <li>How might it be helpful to do a quick review of a website's features when searching the internet for information?</li>
              </OrderedList>
            </StudentCard>
            <TeacherCard
              fullScreen={teacherFullScreen}
              hidden={studentFullScreen}
              highSchool={false}
              setFullScreen={setTeacherFullScreen}
            >
              <p>For the questions on this screen, you can choose whether to have student teams answer all the questions or you can assign a specific question to different teams. Then hold a whole-class discussion to allow students to share their ideas with the class. Also allow time in the discussion too answer any questions that students may have about the rubric or why a quick survey of the presence or absence of specific web features can be helpful.</p>

              <p className="font-bold">Sample answers to questions</p>

              <OrderedList>
                <li>Although the examples in this lesson are websites, people get information from many sources, including magazines, newspapers, TV, social media, and so forth. Regardless of the source, you would hope to see some of the features we identified for websites, including a current date, information about the authors, citations, and so forth. Some features may not apply. A magazine, for example, would not include a URL domain. If you used the rubric to evaluate a magazine article, you would not lower the score because there is no URL domain type. That feature simply does not apply in the case of a magazine.</li>
                <li>A recent date on a website or other media source is especially important when looking for current information about a health issue. If you are looking for the history of a topic, such as the discovery of the polio vaccine, it may not be as important to have the most up-to-date source of information. In this example, what scientists new about the development of a polio vaccine 10 years ago is probably very similar to what we know now.</li>
                <li>Being able to do a quick review of a website-or other media source-can save time and effort. You can complete a quick scan of a website much faster than you can read all the content contained on the site.</li>
              </OrderedList>

              <p>Inform students that they will continue to learn more about how to evaluate health-related information in the upcoming lessons.</p>
            </TeacherCard>
          </TeacherViewWrapper>
        </ContentWrapper>
      </LessonLayout>
  )
}

export default Page;
